<template>
  <b-sidebar
    id="aml-add-black"
    :visible="isAmlAddBlackActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-aml-add-black-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          Add Black
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-card title="Id Info">
        <b-form-group label="ID NO." label-for="id_input">
          <b-form-input
            id="id_input"
            v-model="idNoKey"
            autofocus
            trim
          />
        </b-form-group>
        <b-form-group label="Name." label-for="name_input">
          <b-form-input
            id="name_input"
            v-model="nameKey"
            autofocus
            trim
          />
        </b-form-group>
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            @click="selectInfo"
          >
            Search
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="resetInfo"
          >
            Reset
          </b-button>
        </div>
      </b-card>
      <validation-observer ref="simpleRules">
        <b-form class="p-2">
          <validation-provider
            #default="{ errors }"
            name="idNo"
            rules="required"
          >
            <b-form-group label="ID NO." label-for="idNo">
              <b-form-input
                id="idNo"
                v-model="blackInfo.id_no"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="userName"
            rules="required"
          >
            <b-form-group label="User Name" label-for="userName">
              <b-form-input
                id="userName"
                v-model="blackInfo.user_name"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            name="idNo"
            rules="required"
          >
            <b-form-group label="ID_TYPE" label-for="id_type">
              <b-form-input
                id="id_type"
                v-model="blackInfo.id_type"
                autofocus
                trim
                :disabled="true"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="EMAIL" label-for="email">
            <b-form-input
              id="email"
              v-model="blackInfo.email"
              autofocus
              trim
            />
          </b-form-group>
          <b-form-group label="TEL" label-for="mobile">
            <b-form-input
              id="mobile"
              v-model="blackInfo.mobile"
              autofocus
              trim
            />
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Reason"
            rules="required"
          >
            <b-form-group label="Reason" label-for="reason">
              <b-form-input
                id="reason"
                v-model="blackInfo.reason"
                autofocus
                trim
                :state="errors.length > 0 ? false : null"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Remark" label-for="remark">
            <b-form-textarea
              id="remark"
              v-model="blackInfo.remark"
              rows="3"
              no-resize
            />
          </b-form-group>
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import amlIdListJs from './amlIdList'

const {
  saveIdAml,
  fetchAmlIdList,
} = amlIdListJs()

export default {
  components: {
    BSidebar,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BCard,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAmlAddBlackActive',
    event: 'update:is-aml-add-black-active',
  },
  props: {
    isAmlAddBlackActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      idNoKey: '',
      nameKey: '',
      searchRequest: {},
      blackInfo: {
        reason: '',
        remark: '',
        id_no: '',
        id_type: 'CPF',
        user_name: '',
        mobile: '',
        email: '',
      },
      required,
      amls: [],
      other: [],
    }
  },
  watch: {
    isAmlAddBlackActive() {
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.updateItem()
            }
          })
        }
      })
    },
    updateItem() {
      this.amls = []
      this.blackInfo.update_user = this.getUserName()
      this.amls.push(this.blackInfo)
      saveIdAml(
        this.amls,
        () => {
          this.showAlert('success', 'Add Blacklist', 'Save Ok')
          this.$emit('update:is-aml-add-black-active', false)
          this.$emit('updated')
        },
        fail => {
          this.showAlert('warning', `Add Blacklist Error ${fail}`)
        },
      )
    },
    selectInfo() {
      this.searchRequest.page_size = 5
      this.searchRequest.page_num = 1
      this.searchRequest.name = this.nameKey
      this.searchRequest.id_no = this.idNoKey
      this.searchRequest.user_name = this.getUserName()
      fetchAmlIdList(
        this.searchRequest,
        records => {
          if (records.list !== undefined && records.list !== null && records.list.length > 0) {
            this.blackInfo = records.list.shift()
          }
        },
      )
    },
    resetInfo() {
      this.idNoKey = ''
      this.nameKey = ''
    },
    getUserName() {
      const userString = localStorage.getItem('userData')
      if (userString !== null && userString !== undefined) {
        const user = JSON.parse(userString)
        return user.realName
      }
      return ''
    },
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#aml-add-black {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  .text-center-align {
    text-align: center;
  }
}
</style>
