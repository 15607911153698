<template>
  <div>
    <b-card title="Filter">
      <b-row>
        <b-col md="3" class="mb-md-0 mb-2">
          <label>ID</label>
          <b-form-input
            id="cpfInput"
            v-model="cpfKey"
            placeholder="ID (CPF/CNPJ)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Name</label>
          <b-form-input
            id="nameInput"
            v-model="nameKey"
            placeholder="USER NAME"
          />
        </b-col>

        <b-col md="5" class="mb-md-0 mb-2">
          <b-button
            class="mt-2 mr-3"
            variant="primary"
            @click="search"
          >
            Search
          </b-button>
          <b-button
            class="mt-2 mr-3"
            variant="outline-primary"
            @click="reset"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              @click="releaseBlack"
            >
              <feather-icon
                icon="UserCheckIcon"
                class="mr-50"
              />
              <span class="align-middle">Release Black</span>
            </b-button>
            <b-button
              class="ml-1"
              variant="outline-primary"
              @click="addblack"
            >
              <feather-icon
                icon="UserXIcon"
                class="mr-50"
              />
              <span class="align-middle">Add Black</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="records"
        primary-key="id_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(choose)="data">
          <b-form-checkbox v-model="data.item.is_check" />
        </template>
        <template #cell(id_no)="data">
          <b-link
            target="_blank"
            :to="{ name: 'aml-black-detail', query:{id_no: data.item.id_no, id_type: data.item.id_type} }"
            class="font-weight-bold d-block"
          >
            {{ data.item.id_no }}
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <Aml-Add-Black
        :is-aml-add-black-active.sync="isAmlAddBlackActive"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BButton, BCard, BRow, BCol, BTable, BPagination, BLink, BFormCheckbox,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import amlIdListJs from './amlIdList'
import AmlAddBlack from './AmlAddBlack.vue'

const {
  deleteIdAml,
  downloadAmlBlackList,
  fetchAmlBlackList,
} = amlIdListJs()

export default {
  components: {
    BFormInput,
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BLink,
    AmlAddBlack,
    BFormCheckbox,
  },
  data() {
    return {
      cpfKey: '',
      nameKey: '',
      searchRequest: {},
      records: [],
      veritys: [],
      createRange: [],
      updateRange: [],
      isShowSuccess: true,
      isAmlAddBlackActive: false,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      currentPage: 1,
      total: 0,
      tableFields: [
        { key: 'choose', label: 'choose' },
        { key: 'id_no', label: 'ID NO.' },
        { key: 'id_type', label: 'ID Type' },
        { key: 'user_name', label: 'Name' },
        { key: 'create_time', label: 'Create Time' },
        { key: 'update_time', label: 'Update Time' },
        { key: 'reason', label: 'Black Reason' },
        { key: 'user_name', label: 'Operator' },
        { key: 'remark', label: 'Remarks' },
      ],
      idwallFilter: '',
      cpfFilter: '',
      modeOptions: [
        { label: 'ALL', value: '' },
        { label: 'YES', value: '1' },
        { label: 'NO', value: '0' },
      ],
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const end = moment().subtract(1, 'days').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 7 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(6, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'This Month',
          onClick() {
            const end = moment().endOf('month').endOf('day')
              .toDate()
            const start = moment().startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last Month',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 3 Months',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(3, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
      ],
    }
  },
  watch: {
    createRange: function formatWithTz(object) {
      const startTime = object[0]
      const endTime = object[1]
      const startTzTime = moment.tz(startTime, this.tzFilter)
      const endTzTime = moment.tz(endTime, this.tzFilter)
      const startTimeStr = startTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
      const endTimeStr = endTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
      const dateArray = new Array(0)
      dateArray.push(startTimeStr, endTimeStr)
      return dateArray
    },
    updateRange: function formatWithTz(object) {
      const startTime = object[0]
      const endTime = object[1]
      const startTzTime = moment.tz(startTime, this.tzFilter)
      const endTzTime = moment.tz(endTime, this.tzFilter)
      const startTimeStr = startTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
      const endTimeStr = endTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
      const dateArray = new Array(0)
      dateArray.push(startTimeStr, endTimeStr)
      return dateArray
    },
    currentPage: function refetchSearchData(pageNum) {
      this.searchRequest.page_num = pageNum
      this.search(this.searchRequest)
    },
  },
  created() {
    this.createRange = [moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
    this.updateRange = [moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')]
    this.isShowSuccess = false
    this.search(this.searchRequest)
  },
  methods: {
    formatVerity(value) {
      if (value !== undefined && value !== null) {
        return 'YES'
      }
      return 'NO'
    },
    isVerify(value) {
      switch (value) {
        case '':
          return null
        case '1':
          return true
        case '0':
          return false
        default:
          return null
      }
    },
    getUserName() {
      const userString = localStorage.getItem('userData')
      if (userString !== null && userString !== undefined) {
        const user = JSON.parse(userString)
        return user.realName
      }
      return ''
    },
    getUserId() {
      const userString = localStorage.getItem('userData')
      if (userString !== null && userString !== undefined) {
        const user = JSON.parse(userString)
        return user.uid
      }
      return ''
    },
    reset() {
      this.cpfKey = ''
      this.nameKey = ''
      this.createRange = []
      this.updateRange = []
      this.searchRequest.page_num = 1
    },
    addblack() {
      this.isAmlAddBlackActive = true
    },
    releaseBlack() {
      this.veritys = []
      const userName = this.getUserName()
      this.records.forEach(item => {
        if (item.is_check) {
          const cpfRequest = {}
          cpfRequest.id_no = item.id_no
          cpfRequest.name = item.user_name
          cpfRequest.id_type = item.id_type
          cpfRequest.user_name = userName
          this.veritys.push(cpfRequest)
        }
      })
      if (this.veritys === undefined || this.veritys === null || this.veritys.length <= 0) {
        this.showAlert('warning', 'Please Select Release Record')
        return
      }
      deleteIdAml(
        this.veritys,
        () => {
          this.showAlert('success', 'Release Blacklist Success', 'Save Ok')
          this.isShowSuccess = false
          this.search(this.searchRequest)
        },
        fail => {
          this.showAlert('warning', `Fetch Verify with ${fail}`)
        },
      )
    },
    download() {
      this.searchRequst()
      downloadAmlBlackList(
        this.searchRequest,
        result => {
          window.open(result)
        },
        fail => {
          this.showAlert('warning', `Fetch download with ${fail}`)
        },
      )
    },
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    searchRequst() {
      this.searchRequest.name = this.nameKey
      this.searchRequest.id_no = this.cpfKey
      this.searchRequest.user_name = this.getUserName()
      this.searchRequest.uid = this.getUserId()
    },
    search(params) {
      this.searchRequest = params
      this.searchRequst()
      fetchAmlBlackList(
        this.searchRequest,
        records => {
          this.records = records.list
          this.total = records.total
          this.dataMeta.from = this.currentPage * 10 - (10 - 1)
          this.dataMeta.to = this.total - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.total
          this.dataMeta.of = this.total
          if (this.isShowSuccess) {
            this.$bvToast.toast('Success fetching data', {
              title: 'Success',
              icon: 'AlertTriangleIcon',
              variant: 'success',
              toaster: 'b-toaster-top-right',
              solid: true,
            })
          }
          this.isShowSuccess = true
        },
        fail => {
          this.showAlert('warning', `Fetch records with ${fail}`)
        },
      )
    },
  },
}
</script>
