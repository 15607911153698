var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"aml-add-black","visible":_vm.isAmlAddBlackActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-aml-add-black-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n      "},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Add Black ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('b-card',{attrs:{"title":"Id Info"}},[_c('b-form-group',{attrs:{"label":"ID NO.","label-for":"id_input"}},[_c('b-form-input',{attrs:{"id":"id_input","autofocus":"","trim":""},model:{value:(_vm.idNoKey),callback:function ($$v) {_vm.idNoKey=$$v},expression:"idNoKey"}})],1),_c('b-form-group',{attrs:{"label":"Name.","label-for":"name_input"}},[_c('b-form-input',{attrs:{"id":"name_input","autofocus":"","trim":""},model:{value:(_vm.nameKey),callback:function ($$v) {_vm.nameKey=$$v},expression:"nameKey"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.selectInfo}},[_vm._v(" Search ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.resetInfo}},[_vm._v(" Reset ")])],1)],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"p-2"},[_c('validation-provider',{attrs:{"name":"idNo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ID NO.","label-for":"idNo"}},[_c('b-form-input',{attrs:{"id":"idNo","autofocus":"","trim":"","state":errors.length > 0 ? false : null},model:{value:(_vm.blackInfo.id_no),callback:function ($$v) {_vm.$set(_vm.blackInfo, "id_no", $$v)},expression:"blackInfo.id_no"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"userName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"User Name","label-for":"userName"}},[_c('b-form-input',{attrs:{"id":"userName","autofocus":"","trim":"","state":errors.length > 0 ? false : null},model:{value:(_vm.blackInfo.user_name),callback:function ($$v) {_vm.$set(_vm.blackInfo, "user_name", $$v)},expression:"blackInfo.user_name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"idNo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ID_TYPE","label-for":"id_type"}},[_c('b-form-input',{attrs:{"id":"id_type","autofocus":"","trim":"","disabled":true,"state":errors.length > 0 ? false : null},model:{value:(_vm.blackInfo.id_type),callback:function ($$v) {_vm.$set(_vm.blackInfo, "id_type", $$v)},expression:"blackInfo.id_type"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"EMAIL","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","autofocus":"","trim":""},model:{value:(_vm.blackInfo.email),callback:function ($$v) {_vm.$set(_vm.blackInfo, "email", $$v)},expression:"blackInfo.email"}})],1),_c('b-form-group',{attrs:{"label":"TEL","label-for":"mobile"}},[_c('b-form-input',{attrs:{"id":"mobile","autofocus":"","trim":""},model:{value:(_vm.blackInfo.mobile),callback:function ($$v) {_vm.$set(_vm.blackInfo, "mobile", $$v)},expression:"blackInfo.mobile"}})],1),_c('validation-provider',{attrs:{"name":"Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Reason","label-for":"reason"}},[_c('b-form-input',{attrs:{"id":"reason","autofocus":"","trim":"","state":errors.length > 0 ? false : null},model:{value:(_vm.blackInfo.reason),callback:function ($$v) {_vm.$set(_vm.blackInfo, "reason", $$v)},expression:"blackInfo.reason"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Remark","label-for":"remark"}},[_c('b-form-textarea',{attrs:{"id":"remark","rows":"3","no-resize":""},model:{value:(_vm.blackInfo.remark),callback:function ($$v) {_vm.$set(_vm.blackInfo, "remark", $$v)},expression:"blackInfo.remark"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }